import styled from 'styled-components';

export const StyledSubtitle = styled('h2')`
  text-align: ${({ align }) => align || 'left'};
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.2rem;
  margin-bottom: 2rem;
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mq.md} {
    margin-bottom: 2.4rem;
    font-size: 2.4rem;
    line-height: 2.6rem;
  }
`;
